<template>
  <link
    v-if="maptoolkitEnabled"
    rel="stylesheet"
    href="https://static.maptoolkit.net/mtk/v10.1.10/mtk.css"
  />
  <link
    v-if="maptoolkitEnabled"
    rel="stylesheet"
    href="https://static.maptoolkit.net/mtk/v10.1.10/ui.css"
  />

  <div class="imxMap">
    <template v-if="maptoolkitEnabled">
      <div ref="mtkMapContainer" class="container" />

      <div class="hidden">
        <slot />
      </div>

      <slot name="below" />
    </template>
    <template v-else>
      <BaseConsentPlaceholder
        :title="t('components.consent.maptoolkit.title')"
        :button="t('components.consent.maptoolkit.button')"
        @activate="handleActivateMap()"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, provide, ref, shallowRef, watch } from 'vue';
import { MAPTOOLKIT_APIKEY } from '../../constants.config';
import type { BaseCoords } from '@models/BaseCoords';
import {
  type ConsentManager,
  ConsentManagerSymbol,
  Vendor,
} from '../../utils/consent-manager';
import { convertBaseCoordsToLatLngLike } from '../../utils/convertBaseCoords';
import { ErrorMessage } from '@models/ErrorMessage';
import { loadScript } from '@utils/dom/script-util';

// Define types for MTK and maplibregl to fix linter errors
interface MTKMap {
  gl: {
    resize: () => void;
    scrollZoom: { disable: () => void };
    setZoom: (zoom: number) => void;
    setCenter: (center: unknown) => void;
    flyTo: (options: { center: unknown }) => void;
    fitBounds: (bounds: unknown, options: { padding: number }) => void;
    remove: () => void;
  };
}

interface MaplibreGL {
  NavigationControl: new (options: unknown) => unknown;
  FullscreenControl: new () => unknown;
}

// Update MTK declaration with appropriate type
declare let MTK: {
  language: string;
  WeatherControl: new () => unknown;
  init: (options: { apiKey: string; language: string; i18n: object }) => {
    createMap: (
      container: HTMLElement,
      options: unknown,
      callback: (map: MTKMap) => void
    ) => void;
  };
};

declare let maplibregl: MaplibreGL;

const DEFAULT_MAP_ZOOM_LEVEL = 14;

const { locale, t } = useI18n();

const config = useWhlInstanceConfig();

const hideSmartScrollOverlay = defineModel<boolean>('hideSmartScrollOverlay', {
  default: false,
});

const props = defineProps<{
  center?: BaseCoords;
  zoom?: number;
  bounds?: unknown; // as long as mtk does not provide types we have to deal with unknown values here
  enableFullscreen?: boolean;
}>();

const emit = defineEmits(['mapInit']);

const maptoolkitEnabled: Ref<boolean> = ref(false);

const consentManager = inject<ConsentManager>(ConsentManagerSymbol);

if (!consentManager) {
  throw new Error(ErrorMessage.NO_CONSENT_MANAGER);
}

consentManager.waitForVendor(Vendor.MAPTOOLKIT).then(() => {
  maptoolkitEnabled.value = true;
  loadMtk().then(() => {
    console.log('MapToolkit loaded after consent');
  });
});

// consentManager.isVendorAvailable(Vendor.MAPTOOLKIT).then((available) => {
//   maptoolkitEnabled.value = available;
// })

const handleActivateMap = () => {
  console.log('Activating MapToolkit vendor');
  consentManager.askForVendor(Vendor.MAPTOOLKIT);
};

const mtkMapContainer = ref<HTMLElement | null>(null);
const mtkLoaded = ref<boolean>(false);
const mtkMap = shallowRef<MTKMap | null>(null);
const smartScrollOverlay = ref<HTMLElement | null>(null);

const redrawMapBus = useEventBus<never>('redrawMap');

provide('mtkMap', mtkMap);
provide('mtkMapContainer', mtkMapContainer);

// load mtk only once
const loadMtk = (): Promise<void> => {
  if (typeof MTK === 'undefined') {
    return loadScript('https://static.maptoolkit.net/mtk/v10.1.10/mtk.js')
      .then(() =>
        loadScript(
          'https://static.maptoolkit.net/mtk/v10.1.10/elevationprofile.js'
        )
      )
      .then(() => {
        mtkLoaded.value = true;
      });
  } else {
    mtkLoaded.value = true;
    return Promise.resolve();
  }
};
if (toValue(maptoolkitEnabled)) {
  loadMtk();
}
watch(maptoolkitEnabled, (enabled) => {
  if (enabled) {
    loadMtk();
  }
});

const redrawMap = () => {
  mtkMap.value?.gl.resize();
};
redrawMapBus.on(redrawMap);

const initMap = () => {
  if (mtkLoaded.value && mtkMapContainer.value) {
    const initialCenter: BaseCoords =
      props.center ?? config.value.map.defaultCoords;
    const initialZoom =
      props.zoom ?? config.value.map.defaultZoom ?? DEFAULT_MAP_ZOOM_LEVEL;

    const controls = [
      [
        new maplibregl.NavigationControl({
          showCompass: false,
          visualizePitch: false,
        }),
        'top-right',
      ],
    ];
    if (config.value.map.weatherControls) {
      controls.push([new MTK.WeatherControl(), 'top-left']);
    }
    if (props.enableFullscreen) {
      controls.push([new maplibregl.FullscreenControl(), 'top-right']);
    }

    MTK.init({
      apiKey: config.value?.map?.maptoolkitApiKey ?? MAPTOOLKIT_APIKEY,
      language: locale.value, // not completely used for translations
      i18n: {
        'Use two fingers to pan': t('components.mtkmap.useTwoFingersToPan'),
        'NavigationControl.ZoomIn': t(
          'components.mtkmap.navigationControl.zoomIn'
        ),
        'NavigationControl.ZoomOut': t(
          'components.mtkmap.navigationControl.zoomOut'
        ),
      },
    }).createMap(
      mtkMapContainer.value as HTMLElement,
      {
        map: {
          location: {
            center: convertBaseCoordsToLatLngLike(initialCenter),
            zoom: initialZoom,
          },
          controls: controls,
          mapType: 'toursprung-terrain',
          options: {
            dragRotate: false,
            pitchWithRotate: false,
          },
          smartScroll: config.value.map.smartScroll ?? true,
        },
      },
      (map: MTKMap) => {
        map.gl.scrollZoom.disable();
        map.gl.setZoom(initialZoom);
        map.gl.setCenter(convertBaseCoordsToLatLngLike(initialCenter));

        mtkMap.value = map;
        emit('mapInit');

        // after the map is initialized, we can access the smartScrollOverlay
        smartScrollOverlay.value = document.querySelector(
          '.mtk-map-smartscroll'
        ) as HTMLElement | null;
        if (smartScrollOverlay.value) {
          // needed otherwise the element is too widely
          smartScrollOverlay.value.style.boxSizing = 'border-box';
        }
      }
    );

    /* trigger resize map, as map is not rendered correctly in fullscreen mode on Edge/Chrome */
    mtkMapContainer.value.addEventListener('fullscreenchange', redrawMap);
    mtkMapContainer.value.addEventListener('visibilitychange', redrawMap);
  }
};

watch(mtkLoaded, initMap);
watch(mtkMapContainer, initMap);
watch(
  () => props.center,
  (center) => {
    if (center && mtkMap.value) {
      redrawMap();
      mtkMap.value.gl.flyTo({ center: convertBaseCoordsToLatLngLike(center) });
    }
  }
);
watch(
  () => props.zoom,
  (zoom) => {
    if (zoom && mtkMap.value) {
      redrawMap();
      mtkMap.value.gl.setZoom(zoom);
    }
  }
);
watch(
  () => props.bounds,
  (bounds) => {
    if (bounds && mtkMap.value) {
      redrawMap();
      mtkMap.value.gl.fitBounds(bounds, { padding: 50 });
    }
  }
);

let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;
watchEffect(() => {
  if (timeoutId) clearTimeout(timeoutId);
  timeoutId = undefined;
  if (smartScrollOverlay.value) {
    if (hideSmartScrollOverlay.value) {
      smartScrollOverlay.value.style.visibility = 'hidden';
      hideSmartScrollOverlay.value = false;
    } else {
      timeoutId = setTimeout(() => {
        if (smartScrollOverlay.value) {
          smartScrollOverlay.value.style.visibility = 'visible';
        }
      }, 3000);
    }
  }
});

onBeforeUnmount(() => {
  if (mtkMap.value) {
    mtkMap.value.gl.remove();
    mtkMap.value = null;
  }

  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  if (timeoutId) {
    clearTimeout(timeoutId);
  }
});
</script>

<style lang="scss" scoped>
.imxMap {
  --local-text--color-dark: var(--color-text-bodytext_default);
  --local-background-color: var(--color-surfaces-light);
  --local-item--padding: #{easy-rem(10)};
}

.imxMap {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;

  .container {
    width: 100%;
    flex: 100%;
    background: var(--local-background-color);

    @include containerSMmax {
      z-index: 0;
    }
  }

  .hidden {
    display: none;
  }
}
</style>
